import React from "react";
import { Route, Routes } from "react-router-dom";
import { RouterConfig } from "./hooks/config/routerConfig";
import { HappyCoding } from "./routes/happyCoding";

/**
 *
 * @returns {React.ReactElement} the router
 */
export function AppRouter(): React.ReactElement {
  return (
    <Routes>
      <Route path={RouterConfig.root} element={<HappyCoding />}></Route>
    </Routes>
  );
}

import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  Typography,
} from "@mui/joy";

/**
 *
 * @returns {ReactElement} A happy coding page!
 */
export function HappyCoding() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        position: "relative",
        height: "100vh",
        alignItems: "start",
        flex: 1,
        backgroundImage: `linear-gradient(180deg, rgba(33,150,243,0) 0%, rgba(33,150,243,1) 40vw), url(https://storage.googleapis.com/archer-public/assets/truck-highway-pacific.jpg)`,
        backgroundSize: "110vw",
        backgroundRepeat: "no-repeat",
        // backgroundPositionY: "-10vw",
        // backgroundPositionX: "-10vw",
        overflow: "scroll",
      }}
    >
      {/* <Box
        sx={{
          height: "100vh",
          background:
            "linear-gradient(180deg, rgba(33,150,243,0) 0%, rgba(33,150,243,1) 40vw);",
          width: "100%",
          position: "absolute",
          bottom: 0,
        }}
      /> */}
      <Card
        sx={{
          maxWidth: 600,
          flex: 1,
          width: "100%",
          mt: 2,
          display: "flex",
          gap: 1,
          flexDirection: "column",
          m: 2,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <img
            style={{ height: 50 }}
            src="https://storage.googleapis.com/archer-public/assets/isfs.png"
          />
          <img
            style={{ height: 50 }}
            src="https://storage.googleapis.com/archer-public/assets/White%20Arrow%20-%20Powered%20by%20Archer.png"
          />
        </Box>
        <Divider>Driver Forms</Divider>
        <List size="lg">
          <ListItemButton
            onClick={() =>
              window.open(
                "https://docs.google.com/forms/d/e/1FAIpQLSdgisUvPxN1JdyZbEkrzHLYvo0gCqIQyTa9Lp3MByb9yTktGg/viewform"
              )
            }
          >
            <ListItemDecorator>
              <i className="fa-solid fa-money-bill-1"></i>
            </ListItemDecorator>
            Accessorial Pay
          </ListItemButton>
          <ListItemButton
            onClick={() =>
              window.open(
                "https://docs.google.com/forms/d/e/1FAIpQLScUGGxcNkrs1kbOoYYtdpZyyenM-eD-TxVB_9harSQnvYWEVg/viewform"
              )
            }
          >
            <ListItemDecorator>
              <i className="fa-solid fa-truck"></i>
            </ListItemDecorator>
            Equipment Damage Report
          </ListItemButton>
          <ListItemButton
            onClick={() =>
              window.open(
                "https://docs.google.com/forms/d/e/1FAIpQLSdjjgrI0D96ZjcfT5mZ7iubwvygi-RJuuwStco29rhtnCmfqA/viewform"
              )
            }
          >
            <ListItemDecorator>
              <i className="fa-solid fa-wallet"></i>
            </ListItemDecorator>
            Reimbursement
          </ListItemButton>
          <ListItemButton
            onClick={() =>
              window.open(
                "https://docs.google.com/forms/d/e/1FAIpQLSd2bQ2tcaOfW33ZY6wKo_Lf2UHatfZNy-c9Nf8Dfl6X89Txug/viewform"
              )
            }
          >
            <ListItemDecorator>
              <i className="fa-solid fa-island-tropical"></i>
            </ListItemDecorator>
            Time Off Request
          </ListItemButton>
          <ListItemButton
            onClick={() =>
              window.open(
                "https://docs.google.com/forms/d/e/1FAIpQLSewuCnYoYNtJwrQk7ZEDNd1wjttsWsYga0-tPCMuoV6t7Gutg/viewform"
              )
            }
          >
            <ListItemDecorator>
              <i className="fa-solid fa-wine-glass-crack"></i>
            </ListItemDecorator>
            OS&D Reporting
          </ListItemButton>
          <ListItemButton
            onClick={() =>
              window.open(
                "https://docs.google.com/forms/d/e/1FAIpQLSd2QHtTfqh_Ey72oyE3ZpAjbClbM7knrrL28VrU1fZOlCPjGg/viewform"
              )
            }
          >
            <ListItemDecorator>
              <i className="fa-sharp fa-solid fa-steering-wheel"></i>
            </ListItemDecorator>
            Near Miss Reporting
          </ListItemButton>
          <ListItemButton
            onClick={() =>
              window.open(
                "https://docs.google.com/forms/d/e/1FAIpQLSfRrIFwgtzUPUOcCo_EgGDrbJI-_zXEyDaVI9E0oA8NlmrO-g/viewform"
              )
            }
          >
            <ListItemDecorator>
              <i className="fa-solid fa-car-burst"></i>
            </ListItemDecorator>
            Accident reporting
          </ListItemButton>
          <ListItemButton
            onClick={() =>
              window.open(
                "https://drive.google.com/drive/folders/1kr8JbWDFBE39-uztc9zlTUQFMcmaJ9kH?usp=sharing"
              )
            }
          >
            <ListItemDecorator>
              <i className="fa-solid fa-folder-open"></i>
            </ListItemDecorator>
            Fleet documents
          </ListItemButton>
        </List>
        <Box sx={{ textAlign: "center" }}>
          <Link
            startDecorator={<i className="fa-regular fa-envelope"></i>}
            color="neutral"
            level="body3"
            href="mailto:salberg@whitearrow.com"
          >
            Have an issue with a form? Contact Form Admin
          </Link>
        </Box>
        <Divider>Useful Contacts</Divider>
        <Typography>
          <b>Terminal Managers</b>
        </Typography>
        <List size="lg">
          <ListItem>
            <ListItemContent> MA, NJ, PA, IL</ListItemContent>
            <Typography sx={{ textAlign: "right" }}>
              <Link href="mailto:elvis@whitearrow.com">
                elvis@whitearrow.com
              </Link>
              <br />
              <Link href="tel:909-786-3351">909-786-3351</Link>
            </Typography>
          </ListItem>
          <ListItem>
            <ListItemContent>SoCal</ListItemContent>
            <Typography sx={{ textAlign: "right" }}>
              <Link href="mailto:robert@whitearrow.com">
                robert@whitearrow.com
              </Link>
              <br />
              <Link href="tel:909-786-3341">909-786-3341</Link>
            </Typography>
          </ListItem>
          <ListItem>
            <ListItemContent>NorthCal, OR</ListItemContent>
            <Typography sx={{ textAlign: "right" }}>
              <Link href="mailto:mike@whitearrow.com">mike@whitearrow.com</Link>
              <br />
              <Link href="tel:909-786-3334">909-786-3334</Link>
            </Typography>
          </ListItem>
        </List>
        <Typography>
          <b>Dispatch</b>
        </Typography>
        <List size="lg">
          <ListItem>
            <ListItemContent> MA, NJ, PA</ListItemContent>
            <Typography sx={{ textAlign: "right" }}>
              <Link href="mailto:dispatch@whitearrow.com">
                dispatch@whitearrow.com
              </Link>
              <br />
              <Link href="tel:909-786-3361">909-786-3361</Link>
            </Typography>
          </ListItem>
          <ListItem>
            <ListItemContent>IL</ListItemContent>
            <Typography sx={{ textAlign: "right" }}>
              <Link href="mailto:dispatch@whitearrow.com">
                dispatch@whitearrow.com
              </Link>
              <br />
              <Link href="tel:708-390-8281">708-390-8281</Link>
            </Typography>
          </ListItem>
          <ListItem>
            <ListItemContent>CA</ListItemContent>
            <Typography sx={{ textAlign: "right" }}>
              <Link href="mailto:dispatch@whitearrow.com">
                dispatch@whitearrow.com
              </Link>
              <br />
              <Link href="tel:909-786-3330">909-786-3330</Link>
            </Typography>
          </ListItem>
          <ListItem>
            <ListItemContent>OR</ListItemContent>
            <Typography sx={{ textAlign: "right" }}>
              <Link href="mailto:dispatch@whitearrow.com">
                dispatch@whitearrow.com
              </Link>
              <br />
              <Link href="tel:909-377-1189">909-377-1189</Link>
            </Typography>
          </ListItem>
          <ListItem>
            <ListItemContent>After hours</ListItemContent>
            <Typography sx={{ textAlign: "right" }}>
              <Link href="mailto:dispatch@whitearrow.com">
                dispatch@whitearrow.com
              </Link>
              <br />
              <Link href="tel:909-786-3333">909-786-3333</Link>
            </Typography>
          </ListItem>
        </List>
        <Typography>
          <b>Fleet</b>
        </Typography>
        <List size="lg">
          <ListItem>
            <ListItemContent></ListItemContent>
            <Typography sx={{ textAlign: "right" }}>
              <Link href="mailto:mitch@whitearrow.com">
                mitch@whitearrow.com
              </Link>
              <br />
              <Link href="tel:909-786-3364">909-786-3364</Link>
            </Typography>
          </ListItem>
        </List>
        <Typography>
          <b>Safety</b>
        </Typography>
        <List size="lg">
          <ListItem>
            <ListItemContent></ListItemContent>
            <Typography sx={{ textAlign: "right" }}>
              <Link href="mailto:safety@whitearrow.com">
                safety@whitearrow.com
              </Link>
              <br />
              <Link href="tel:213-234-9127">213-234-9127</Link>
            </Typography>
          </ListItem>
        </List>
        <Typography>
          <b>Human Resources</b>
        </Typography>
        <List size="lg">
          <ListItem>
            <ListItemContent></ListItemContent>
            <Typography sx={{ textAlign: "right" }}>
              <Link href="mailto:hr@whitearrow.com,">hr@whitearrow.com,</Link>
              <br />
              <Link href="tel:909-736-1092">909-736-1092</Link>
            </Typography>
          </ListItem>
        </List>
        <Divider>Other</Divider>
        <Button
          size="lg"
          startDecorator={<i className="fa-regular fa-circle-6"></i>}
          color="neutral"
          variant="soft"
          onClick={() => {
            window.open(
              "https://docs.google.com/document/d/1Ia_UnSTJhnbduhEP38YUgn8MXOUGyi_UqrUvVRKVdbw/edit#heading=h.16rd6m4bymel"
            );
          }}
        >
          The Serious Six
        </Button>
        <Typography>
          <b>Your Commitment:</b>
        </Typography>
        <List size="lg">
          <ListItem>
            <ListItemDecorator>
              <Checkbox checked />
            </ListItemDecorator>
            <ListItemContent>
              Coming to work focused and never under the influence of any
              alcohol or drugs.
            </ListItemContent>
          </ListItem>
          <ListItem>
            <ListItemDecorator>
              <Checkbox checked />
            </ListItemDecorator>
            <ListItemContent>
              Inspecting my equipment before and after every trip and only
              driving if compliant.
            </ListItemContent>
          </ListItem>
          <ListItem>
            <ListItemDecorator>
              <Checkbox checked />
            </ListItemDecorator>
            <ListItemContent>
              Obeying all safety laws and regulations and avoiding any
              distractions while driving.
            </ListItemContent>
          </ListItem>
          <ListItem>
            <ListItemDecorator>
              <Checkbox checked />
            </ListItemDecorator>
            <ListItemContent>
              Interacting with everyone professionally, and keeping everyone
              safe on the road
            </ListItemContent>
          </ListItem>
          <ListItem>
            <ListItemDecorator>
              <Checkbox checked />
            </ListItemDecorator>
            <ListItemContent>
              Thinking and acting with integrity, honesty, and professionalism.
            </ListItemContent>
          </ListItem>
          <ListItem>
            <ListItemDecorator>
              <Checkbox checked />
            </ListItemDecorator>
            <ListItemContent>
              Always choose the safe road, not the fast road. Always do what is
              safe and right, even when it is not the easiest path.
            </ListItemContent>
          </ListItem>
        </List>
      </Card>
    </Box>
  );
}

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import NerdApp from "./hooks/nerdApp/nerdApp";
import "./index.css";
import { store } from "./redux/store";
import "./i18n";
import { AppConfig } from "./environement";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { createRoot } from "react-dom/client";
import { appTheme } from "./hooks/config/themeConfig";
import { CssVarsProvider } from "@mui/joy";

document.title = AppConfig.app.title || "Nerd Home";
const favIcon = document.getElementById("favicon");
if (favIcon !== null && favIcon instanceof HTMLLinkElement)
  favIcon.href = AppConfig.app.favicon || "";

Sentry.init({
  dsn: "https://cdf242cf4dc44566b6dc88237793a769@o1058683.ingest.sentry.io/6046515",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

//Mui X Pro License Key
LicenseInfo.setLicenseKey(
  "7908c976763f4c481913a58651a92565T1JERVI6MzkwMDIsRVhQSVJZPTE2Nzc5NTg1MjQwMDAsS0VZVkVSU0lPTj0x"
);

const container = document.getElementById("root");

if (container != null) {
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <Provider store={store}>
          <CssVarsProvider theme={appTheme}>
            <NerdApp />
          </CssVarsProvider>
        </Provider>
      </BrowserRouter>
    </React.StrictMode>
  );
}

import { useTheme } from "@mui/joy";
import { Box, CssBaseline, useMediaQuery } from "@mui/material";
import { NerdNotifier, clearNotification } from "@nerdjs/nerd-ui";
import { ReactElement } from "react";
import { AppRouter } from "../../appRouter";
import { AppConfig } from "../../environement";
import { useAppDispatch, useAppSelector } from "../hooks";

/**
 * App wrapper
 *
 * @returns {ReactElement} App wrapper
 */
export default function NerdApp(): ReactElement {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true,
  });

  return (
    <NotifierWrapper>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: md ? "column" : "row",
        }}
      >
        <CssBaseline />
        <AppRouter />
      </Box>
    </NotifierWrapper>
  );
}

const NotifierWrapper = ({ children }: { children: ReactElement }) => {
  const dispatch = useAppDispatch();
  const notification = useAppSelector((s) => s.notification);
  return (
    notification && (
      <NerdNotifier
        {...notification}
        onClose={() => {
          dispatch(clearNotification());
          notification.onClose?.();
        }}
      >
        {children}
      </NerdNotifier>
    )
  );
};
